<template>
  <div>
    <div class="block-content bg-white">
      <div class="row no-gutters flex-md-10-auto">
        <h1>Energy efficiency rating for this property</h1>
      </div>
      <h4>This property’s current energy rating is {{ certificate['asset-rating-band'] }}.</h4>
      <EpcCertificateGraph :asset-rating-band="certificate['asset-rating-band']" :asset-rating="certificate['asset-rating']" />
    </div>
    <div class="block-content bg-white">
      <div>
        <div class="DataTable-wrapper table-wrapper">
          <table class="table table-striped">
            <thead class="bg-dark text-light">
              <tr class="bg-dark text-light">
                <th>Certificate Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(value, name) in certDetails" :key="name">
              <tr>
                <td>
                  <span class="font-w600">{{ name }}</span>
                </td>
                <td>
                  <span>{{ value || noValueText }}</span>
                </td>
              </tr>
            </tbody>
            <thead class="bg-dark text-light">
              <tr class="bg-dark text-light">
                <th>Location</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(value, name) in location" :key="name">
              <tr>
                <td>
                  <span class="font-w600">{{ name }}</span>
                </td>
                <td>
                  <span>{{ value || noValueText }}</span>
                </td>
              </tr>
            </tbody>
            <thead class="bg-dark text-light">
              <tr class="bg-dark text-light">
                <th>Other</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(value, name) in other" :key="name">
              <tr>
                <td>
                  <span class="font-w600">{{ name }}</span>
                </td>
                <td>
                  <span>{{ value || noValueText }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EpcCertificateGraph from './EpcCertificateGraph';

export default {
  name: 'EPCCertificateView',
  components: { EpcCertificateGraph },
  props: {
    certificate: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noValueText: 'No value available'
    };
  },
  computed: {
    certDetails: function () {
      return {
        'Lodgement Date': this.certificate['lodgement-date'],
        'Asset Rating Band': this.certificate['asset-rating-band'],
        'Asset Rating': this.certificate['asset-rating'],
        'New Build Benchmark': this.certificate['new-build-benchmark'],
        'Existing Stock Benchmark': this.certificate['existing-stock-benchmark'],
        'Building Level': this.certificate['building-level']
      };
    },
    location: function () {
      return {
        Address: this.certificate['address'],
        'Post Town': this.certificate['posttown'],
        Postcode: this.certificate['postcode'],
        County: this.certificate['county'],
        'Local Authority': this.certificate['local-authority'],
        Constituency: this.certificate['constituency']
      };
    },
    other: function () {
      return {
        'LMK Key': this.certificate['lmk-key'],
        'Building Reference Number': this.certificate['building-reference-number'],
        'Property Type': this.certificate['property-type'],
        'Inspection Date': this.certificate['inspection-date'],
        'Transaction Type': this.certificate['transaction-type'],
        'Main Heating Fuel': this.certificate['main-heating-fuel'],
        'Other Fuel Desc': this.certificate['other-fuel-desc'],
        'Special Energy Uses': this.certificate['special-energy-uses'],
        'Renewable Sources': this.certificate['renewable-sources']
      };
    }
  }
};
</script>
