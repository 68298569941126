var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "svg-container",
    attrs: {
      "width": "615",
      "height": "426",
      "viewBox": "0 0 615 426"
    }
  }, [_c('line', {
    staticClass: "c02-line",
    attrs: {
      "x1": "0",
      "y1": "65",
      "x2": "415",
      "y2": "65"
    }
  }), _c('rect', {
    staticClass: "band-a-plus-score",
    attrs: {
      "width": "175",
      "height": "50",
      "x": "4",
      "y": "5.0"
    }
  }), _c('text', {
    staticClass: "small",
    attrs: {
      "x": "420",
      "y": "70"
    }
  }, [_vm._v("Net zero CO2")]), _c('rect', {
    staticClass: "band-a-score",
    attrs: {
      "width": "205",
      "height": "50",
      "x": "0",
      "y": "75"
    }
  }), _c('rect', {
    staticClass: "band-b-score",
    attrs: {
      "width": "235",
      "height": "50",
      "x": "0",
      "y": "125"
    }
  }), _c('rect', {
    staticClass: "band-c-score",
    attrs: {
      "width": "265",
      "height": "50",
      "x": "0",
      "y": "175"
    }
  }), _c('rect', {
    staticClass: "band-d-score",
    attrs: {
      "width": "295",
      "height": "50",
      "x": "0",
      "y": "225"
    }
  }), _c('rect', {
    staticClass: "band-e-score",
    attrs: {
      "width": "325",
      "height": "50",
      "x": "0",
      "y": "275"
    }
  }), _c('rect', {
    staticClass: "band-f-score",
    attrs: {
      "width": "355",
      "height": "50",
      "x": "0",
      "y": "325"
    }
  }), _c('rect', {
    staticClass: "band-g-score",
    attrs: {
      "width": "385",
      "height": "50",
      "x": "0",
      "y": "375"
    }
  }), _c('text', {
    staticClass: "letter",
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "0",
      "y": "0"
    }
  }, [_c('tspan', {
    attrs: {
      "x": "115.0",
      "y": "44.0"
    }
  }, [_vm._v("A+")]), _c('tspan', {
    attrs: {
      "x": "157",
      "y": "114"
    }
  }, [_vm._v("A")]), _c('tspan', {
    attrs: {
      "x": "187",
      "y": "164"
    }
  }, [_vm._v("B")]), _c('tspan', {
    attrs: {
      "x": "217",
      "y": "214"
    }
  }, [_vm._v("C")]), _c('tspan', {
    attrs: {
      "x": "247",
      "y": "264"
    }
  }, [_vm._v("D")]), _c('tspan', {
    attrs: {
      "x": "277",
      "y": "314"
    }
  }, [_vm._v("E")]), _c('tspan', {
    attrs: {
      "x": "307",
      "y": "364"
    }
  }, [_vm._v("F")]), _c('tspan', {
    attrs: {
      "x": "337",
      "y": "414"
    }
  }, [_vm._v("G")])]), _c('text', {
    staticClass: "small"
  }, [_c('tspan', {
    attrs: {
      "x": "8",
      "y": "35.0"
    }
  }, [_vm._v("Under 0")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "105"
    }
  }, [_vm._v("0-25")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "155"
    }
  }, [_vm._v("26-50")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "205"
    }
  }, [_vm._v("51-75")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "255"
    }
  }, [_vm._v("76-100")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "305"
    }
  }, [_vm._v("101-125")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "355"
    }
  }, [_vm._v("126-150")]), _c('tspan', {
    attrs: {
      "x": "8",
      "y": "405"
    }
  }, [_vm._v("Over 150")])]), _c('svg', {
    attrs: {
      "role": "presentation",
      "aria-hidden": "true",
      "x": "415",
      "y": _vm.ratingPosition,
      "width": "110",
      "height": "50"
    }
  }, [_c('rect', {
    class: _vm.epcColourStatus,
    attrs: {
      "width": "75",
      "height": "50",
      "x": "25"
    }
  }), _c('polygon', {
    class: _vm.epcColourStatus,
    attrs: {
      "points": "0,25 25,50 25,0 0,25"
    }
  }), _c('text', {
    staticClass: "current-potential-number",
    attrs: {
      "x": "25",
      "y": "32"
    }
  }, [_vm._v(_vm._s(_vm.assetRating) + " |")]), _c('text', {
    staticClass: "small-letter",
    attrs: {
      "x": "75",
      "y": "32"
    }
  }, [_vm._v(_vm._s(_vm.assetRatingBand))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }