<template>
  <svg width="615" height="426" viewBox="0 0 615 426" class="svg-container">
    <line x1="0" y1="65" x2="415" y2="65" class="c02-line"></line>
    <rect width="175" height="50" x="4" y="5.0" class="band-a-plus-score"></rect>
    <text x="420" y="70" class="small">Net zero CO2</text>
    <rect width="205" height="50" x="0" y="75" class="band-a-score"></rect>
    <rect width="235" height="50" x="0" y="125" class="band-b-score"></rect>
    <rect width="265" height="50" x="0" y="175" class="band-c-score"></rect>
    <rect width="295" height="50" x="0" y="225" class="band-d-score"></rect>
    <rect width="325" height="50" x="0" y="275" class="band-e-score"></rect>
    <rect width="355" height="50" x="0" y="325" class="band-f-score"></rect>
    <rect width="385" height="50" x="0" y="375" class="band-g-score"></rect>
    <text role="presentation" aria-hidden="true" x="0" y="0" class="letter">
      <tspan x="115.0" y="44.0">A+</tspan>
      <tspan x="157" y="114">A</tspan>
      <tspan x="187" y="164">B</tspan>
      <tspan x="217" y="214">C</tspan>
      <tspan x="247" y="264">D</tspan>
      <tspan x="277" y="314">E</tspan>
      <tspan x="307" y="364">F</tspan>
      <tspan x="337" y="414">G</tspan>
    </text>
    <text class="small">
      <tspan x="8" y="35.0">Under 0</tspan>
      <tspan x="8" y="105">0-25</tspan>
      <tspan x="8" y="155">26-50</tspan>
      <tspan x="8" y="205">51-75</tspan>
      <tspan x="8" y="255">76-100</tspan>
      <tspan x="8" y="305">101-125</tspan>
      <tspan x="8" y="355">126-150</tspan>
      <tspan x="8" y="405">Over 150</tspan>
    </text>
    <svg role="presentation" aria-hidden="true" x="415" :y="ratingPosition" width="110" height="50">
      <rect width="75" height="50" :class="epcColourStatus" x="25"></rect>
      <polygon points="0,25 25,50 25,0 0,25" :class="epcColourStatus"></polygon>
      <text x="25" y="32" class="current-potential-number">{{ assetRating }} |</text>
      <text x="75" y="32" class="small-letter">{{ assetRatingBand }}</text>
    </svg>
  </svg>
</template>
<script>
export default {
  name: 'EpcCertificateGraph',
  props: {
    assetRating: { type: String, default: '0' },
    assetRatingBand: { type: String, default: 'A' }
  },
  computed: {
    ratingPosition: function () {
      let ratingPosition;
      switch (this.assetRatingBand) {
        case 'A':
          ratingPosition = 75;
          break;
        case 'B':
          ratingPosition = 125;
          break;
        case 'C':
          ratingPosition = 175;
          break;
        case 'D':
          ratingPosition = 225;
          break;
        case 'E':
          ratingPosition = 275;
          break;
        case 'F':
          ratingPosition = 325;
          break;
        case 'G':
          ratingPosition = 375;
          break;
        default:
          ratingPosition = 0;
      }
      return ratingPosition;
    },
    epcColourStatus() {
      let colour;
      switch (this.assetRatingBand) {
        case 'B':
          colour = { 'band-b-score': true };
          break;
        case 'C':
          colour = { 'band-c-score': true };
          break;
        case 'D':
          colour = { 'band-d-score': true };
          break;
        case 'E':
          colour = { 'band-e-score': true };
          break;
        case 'F':
          colour = { 'band-f-score': true };
          break;
        case 'G':
          colour = { 'band-g-score': true };
          break;
        default:
          colour = { 'band-a-score': true };
      }
      return colour;
    }
  }
};
</script>

<style scoped>
.svg-container {
  max-width: 100%;
}
.rectangle {
  stroke-width: 5;
  stroke: green;
  fill: white;
}
.small {
  font-size: 16px;
  font-family: sans-serif;
  fill: #0b0c0c;
  line-height: 50px;
  margin-top: 100px;
  font-weight: bold;
}
.c02-line {
  stroke: lightblue;
  stroke-width: 4;
}
.letter {
  font-size: 40px;
  font-family: sans-serif;
  fill: #0b0c0c;
  font-weight: bold;
}
.band-a-score {
  fill: #008054;
}
.band-b-score {
  fill: #19b459;
}
.band-c-score {
  fill: #8dce46;
}
.band-d-score {
  fill: #ffd500;
}
.band-e-score {
  fill: #fcaa65;
}
.band-f-score {
  fill: #ef8023;
}
.band-g-score {
  fill: #e9153b;
}
.band-a-plus-score {
  stroke-width: 5;
  stroke: #008054;
  fill: white;
}
.current-potential-number {
  font-size: 22px;
  font-family: sans-serif;
  fill: #000;
  line-height: 50px;
  margin-top: 100px;
}
.small-letter {
  font-size: 19px;
  font-family: sans-serif;
  fill: #0b0c0c;
  font-weight: bold;
  text-align: center;
}
</style>
