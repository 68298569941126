var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block-content bg-white"
  }, [_vm._m(0), _c('h4', [_vm._v(" This property’s current energy rating is " + _vm._s(_vm.certificate['operational-rating-band']) + " with a score of " + _vm._s(_vm.certificate['current-operational-rating']) + ". ")]), _c('DecCertificateGraph', {
    attrs: {
      "asset-rating-band": _vm.certificate['operational-rating-band'],
      "asset-rating": _vm.certificate['current-operational-rating']
    }
  })], 1), _c('div', {
    staticClass: "block-content bg-white"
  }, [_c('div', [_c('div', {
    staticClass: "DataTable-wrapper table-wrapper"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_vm._m(1), _vm._l(_vm.certDetails, function (value, name) {
    return _c('tbody', {
      key: name
    }, [_c('tr', [_c('td', [_c('span', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(name))])]), _c('td', [_c('span', [_vm._v(_vm._s(value || _vm.noValueText))])])])]);
  }), _vm._m(2), _vm._l(_vm.location, function (value, name) {
    return _c('tbody', {
      key: name
    }, [_c('tr', [_c('td', [_c('span', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(name))])]), _c('td', [_c('span', [_vm._v(_vm._s(value || _vm.noValueText))])])])]);
  }), _vm._m(3), _vm._l(_vm.other, function (value, name) {
    return _c('tbody', {
      key: name
    }, [_c('tr', [_c('td', [_c('span', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(name))])]), _c('td', [_c('span', [_vm._v(_vm._s(value || _vm.noValueText))])])])]);
  })], 2)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row no-gutters flex-md-10-auto"
  }, [_c('h1', [_vm._v("Energy performance operational rating for this property")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "bg-dark text-light"
  }, [_c('tr', {
    staticClass: "bg-dark text-light"
  }, [_c('th', [_vm._v("Certificate Details")]), _c('th')])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "bg-dark text-light"
  }, [_c('tr', {
    staticClass: "bg-dark text-light"
  }, [_c('th', [_vm._v("Location")]), _c('th')])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "bg-dark text-light"
  }, [_c('tr', {
    staticClass: "bg-dark text-light"
  }, [_c('th', [_vm._v("Other")]), _c('th')])]);
}]

export { render, staticRenderFns }