<template>
  <div v-if="certificate.certificate" data-cy="asset-certificate-view">
    <EpcCertificate v-if="certificate.certificate.type === 'epc'" :certificate="certificate.certificate" />
    <DecCertificate v-else-if="certificate.type === 'dec'" :certificate="certificate.certificate" />
    <BreeamCertificate v-else :certificate="certificate.certificate" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import EpcCertificate from '@/components/EpcCertificate';
import BreeamCertificate from '@/components/BreeamCertificate';
import DecCertificate from '@/components/DecCertificate';

const dateFormat = 'Do MMM YYYY';

export default {
  name: 'AssetCertificateView',
  components: { EpcCertificate, BreeamCertificate, DecCertificate },
  data() {
    return {
      certificate: {}
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset'
    })
  },
  mounted() {
    window.scrollTo(0, 0);

    const certificate = this.asset.documents.find(value => value.certificate['building-reference-number'] === this.$route.params.certificateId);

    if (this.certificate.type !== 'breeam') {
      certificate.certificate['lodgement-date'] = moment(certificate.certificate['lodgement-date']).format(dateFormat);
      certificate.certificate['inspection-date'] = moment(certificate.certificate['inspection-date']).format(dateFormat);
    }

    this.certificate = certificate;
  }
};
</script>
