<template>
  <svg
    width="615"
    height="386"
    viewBox="0 0 615 386"
    xmlns="http://www.w3.org/2000/svg"
    style="max-width: 100%"
    aria-labelledby="svg-title"
    aria-describedby="svg-desc"
    role="img"
    class="govuk-!-margin-bottom-5"
  >
    <title id="svg-title">Operational energy efficiency chart</title>
    <desc id="svg-desc">This building’s operational energy rating is {{ assetRatingBand }} with a score of {{ assetRating }}.</desc>

    <line x1="72" y1="0" x2="72" y2="386" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="410" y1="0" x2="410" y2="386" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="530" y1="0" x2="530" y2="386" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="0" y1="25" x2="615" y2="25" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="0" y1="0" x2="615" y2="0" style="stroke: #b1b4b6; stroke-width: 1"></line>

    <line x1="0" y1="0" x2="0" y2="386" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="615" y1="386" x2="615" y2="0" style="stroke: #b1b4b6; stroke-width: 1"></line>
    <line x1="615" y1="386" x2="0" y2="386" style="stroke: #b1b4b6; stroke-width: 1"></line>

    <rect width="78" height="50" x="72" y="25" class="band-a"></rect>
    <rect width="118" height="50" x="72" y="75" class="band-b"></rect>
    <rect width="158" height="50" x="72" y="125" class="band-c"></rect>
    <rect width="198" height="50" x="72" y="175" class="band-d"></rect>
    <rect width="250.0" height="50" x="72" y="240.0" class="band-e"></rect>
    <rect width="286.0" height="50" x="72" y="285.0" class="band-f"></rect>
    <rect width="326.0" height="50" x="72" y="335.0" class="band-g"></rect>

    <rect width="72" height="50" x="0" y="25" class="band-a-score"></rect>
    <rect width="72" height="50" x="0" y="75" class="band-b-score"></rect>
    <rect width="72" height="50" x="0" y="125" class="band-c-score"></rect>
    <rect width="72" height="50" x="0" y="175" class="band-d-score"></rect>
    <rect width="72" height="50" x="0" y="240.0" class="band-e-score"></rect>
    <rect width="72" height="50" x="0" y="285.0" class="band-f-score"></rect>
    <rect width="72" height="50" x="0" y="335.0" class="band-g-score"></rect>

    <text role="presentation" aria-hidden="true" x="0" y="0" class="letter">
      <tspan x="107" y="64">A</tspan>
      <tspan x="147" y="114">B</tspan>
      <tspan x="187" y="164">C</tspan>
      <tspan x="227" y="214">D</tspan>
      <tspan x="279.0" y="279.0">E</tspan>
      <tspan x="315.0" y="324.0">F</tspan>
      <tspan x="355.0" y="374.0">G</tspan>
    </text>

    <text role="presentation" aria-hidden="true" x="0" y="0" class="small">
      <tspan x="8" y="55">0-25</tspan>
      <tspan x="8" y="105">26-50</tspan>
      <tspan x="8" y="155">51-75</tspan>
      <tspan x="8" y="205">76-100</tspan>
      <tspan x="8" y="270.0">101-125</tspan>
      <tspan x="8" y="315.0">126-150</tspan>
      <tspan x="8" y="365.0">150+</tspan>
    </text>

    <text role="presentation" aria-hidden="true" x="8" y="15" class="small" dominant-baseline="middle">Score</text>

    <text role="presentation" aria-hidden="true" x="85" y="15" class="small" dominant-baseline="middle">Operational rating</text>

    <text role="presentation" aria-hidden="true" x="470" y="15" class="small" text-anchor="middle" dominant-baseline="middle">This building</text>

    <text role="presentation" aria-hidden="true" x="570" y="15" class="small" text-anchor="middle" dominant-baseline="middle">Typical</text>

    <line x1="0" y1="235" x2="558" y2="235" stroke-dasharray="3,3" style="stroke: black; stroke-width: 2"></line>
    <line x1="625" y1="235" x2="598" y2="235" stroke-dasharray="3,3" style="stroke: black; stroke-width: 2"></line>
    <text role="presentation" aria-hidden="true" x="0" y="0" class="typical">
      <tspan x="560" y="240">100</tspan>
    </text>

    <svg role="presentation" aria-hidden="true" x="420" :y="ratingPosition" width="90" height="50">
      <rect width="75" height="50" :class="decColourStatus" x="25"></rect>
      <polygon points="0,25 25,50 25,0 0,25" :class="decColourStatus"></polygon>
      <text x="60%" y="50%" dominant-baseline="middle" text-anchor="middle" class="govuk-!-font-weight-bold">
        {{ assetRating }} | {{ assetRatingBand }}
      </text>
    </svg>
  </svg>
</template>
<script>
export default {
  name: 'DecCertificateGraph',
  props: {
    assetRating: { type: String, default: '0' },
    assetRatingBand: { type: String, default: 'A' }
  },
  computed: {
    ratingPosition: function () {
      let ratingPosition;
      switch (this.assetRatingBand) {
        case 'A':
          ratingPosition = 30;
          break;
        case 'B':
          ratingPosition = 75;
          break;
        case 'C':
          ratingPosition = 130;
          break;
        case 'D':
          ratingPosition = 175;
          break;
        case 'E':
          ratingPosition = 240;
          break;
        case 'F':
          ratingPosition = 285;
          break;
        case 'G':
          ratingPosition = 335;
          break;
        default:
          ratingPosition = 0;
      }
      return ratingPosition;
    },
    decColourStatus() {
      let colour;
      switch (this.assetRatingBand) {
        case 'B':
          colour = { 'band-b': true };
          break;
        case 'C':
          colour = { 'band-c': true };
          break;
        case 'D':
          colour = { 'band-d': true };
          break;
        case 'E':
          colour = { 'band-e': true };
          break;
        case 'F':
          colour = { 'band-f': true };
          break;
        case 'G':
          colour = { 'band-g': true };
          break;
        default:
          colour = { 'band-a': true };
      }
      return colour;
    }
  }
};
</script>

<style>
.letter {
  font-size: 40px;
  font-family: sans-serif;
  fill: #0b0c0c;
  font-weight: bold;
}

.small {
  font-size: 16px;
  font-family: sans-serif;
  fill: #0b0c0c;
  line-height: 50px;
  margin-top: 100px;
  font-weight: bold;
}
.band-a-score {
  fill: #67a687;
}
.band-b-score {
  fill: #72ca8b;
}
.band-c-score {
  fill: #b4df86;
}
.band-d-score {
  fill: #ffe666;
}
.band-e-score {
  fill: #fdc79b;
}
.band-f-score {
  fill: #f4ac71;
}
.band-g-score {
  fill: #f2738a;
}
.band-a {
  fill: #008054;
}
.band-b {
  fill: #19b459;
}
.band-c {
  fill: #8dce46;
}
.band-d {
  fill: #ffd500;
}
.band-e {
  fill: #fcaa65;
}
.band-f {
  fill: #ef8023;
}
.band-g {
  fill: #e9153b;
}

.svg-container {
  max-width: 100%;
}
.rectangle {
  stroke-width: 5;
  stroke: green;
  fill: white;
}
.small {
  font-size: 16px;
  font-family: sans-serif;
  fill: #0b0c0c;
  line-height: 50px;
  margin-top: 100px;
  font-weight: bold;
}
.c02-line {
  stroke: lightblue;
  stroke-width: 4;
}
.letter {
  font-size: 40px;
  font-family: sans-serif;
  fill: #0b0c0c;
  font-weight: bold;
}

.band-a-plus-score {
  stroke-width: 5;
  stroke: #008054;
  fill: white;
}
.current-potential-number {
  font-size: 22px;
  font-family: sans-serif;
  fill: #000;
  line-height: 50px;
  margin-top: 100px;
}
.small-letter {
  font-size: 19px;
  font-family: sans-serif;
  fill: #0b0c0c;
  font-weight: bold;
  text-align: center;
}

.govuk-\!-font-weight-bold {
  font-weight: 700 !important;
}
</style>
