<template>
  <div>
    <div class="block-content bg-white">
      <div class="row no-gutters flex-md-10-auto">
        <h1>BREEAM Certification</h1>
      </div>
    </div>
    <div class="block-content bg-white">
      <div>
        <div class="DataTable-wrapper table-wrapper">
          <table class="table table-striped">
            <thead class="bg-dark text-light">
              <tr class="bg-dark text-light">
                <th>Certificate Details</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(value, name) in certDetails" :key="name">
              <tr>
                <td>
                  <span class="font-w600">{{ name }}</span>
                </td>
                <td>
                  <span>{{ value || noValueText }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BreeamCertificateView',
  components: {},
  props: {
    certificate: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noValueText: 'No value available'
    };
  },
  computed: {
    certDetails: function () {
      return {
        'Building Name': this.certificate.buildingName,
        'Certification Body': this.certificate.certBody,
        'Certification Number': this.certificate.certNo,
        'Certification Date': this.certificate.certDate,
        City: this.certificate.city,
        'Company ID': this.certificate.companyID,
        Developer: this.certificate.developer,
        'Latitude / Longitude': this.certificate.latitude + ' / ' + this.certificate.longitude,
        'Project Type': this.certificate.projectType,
        Rating: this.certificate.rating,
        Scheme: this.certificate.scheme,
        Score: this.certificate.score,
        'Section ID': this.certificate.sectionId,
        Stage: this.certificate.stage,
        Standard: this.certificate.standard
      };
    }
  }
};
</script>
